/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-17 10:29:50
 * @Module: 筛选表单
 */
 <template>
  <el-form :inline="true" :model="searchForm" size="mini" style="margin-top:20px;">
    <el-form-item label="关键词">
      <el-input v-model="searchForm.keyword" placeholder="关键词" clearable></el-input>
    </el-form-item>

    <el-form-item label="收款方">
      <el-select v-model="searchForm.payee" filterable placeholder="请选择收款方" clearable>
        <el-option v-for="item in payees" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="承办律师">
      <el-select v-model="searchForm.lawyer" filterable placeholder="请选择承办律师" clearable>
        <el-option v-for="item in lawyers" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="结清">
      <el-select v-model="searchForm.settled" filterable placeholder="结清" clearable>
        <el-option key="" label="全部" value="">
        </el-option>
        <el-option key="true" label="已结清" :value="true">
        </el-option>
        <el-option key="false" label="未结清" :value="false">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>

</template>
 <script>

export default {
  components: {
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        keyword: '',
        areaCode: '',
        legalUuid: '',
        signerUuid: ''
      })
    }
  },
  data () {
    return {
      payees: [],
      lawyers: []
    };
  },
  mounted () {
    this.getIcaseFilter();
  },
  methods: {
    async getIcaseFilter () {
      const { data, status } = await this.$api.getIcaseFilter()
      if (status === 200) {
        this.lawyers = data.lawyers;
        this.payees = data.payees;
      }
    },

    onSubmit () {
      this.$emit('submit')
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>